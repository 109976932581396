<template>
  <div class="field">
    <label class="label has-text-left">{{ labelText }}</label>
    <div class="control">
      <textarea :name="fieldName" :disabled="disabled" v-model="value" :placeholder="placeHolder" :model="value"
        :class="{ 'textarea': true, 'input': true, 'is-danger': errors.has(fieldName) }"
        v-on:input="$emit('input', $event.target.value)" v-on:keyup.enter="$emit('enter')" :data-vv-as="labelText"
        v-validate="rule" :rows="rows"></textarea>
      <span v-show="errors.has(fieldName)" class="help is-danger">{{ errors.first(fieldName) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DoodleTextArea',
  inject: ['$validator'],
  props: ['labelText', 'model', 'rule', 'placeHolder', 'fieldName', 'disabled', 'rows'],
  data: function () {
    return {
      value: this.model
    }
  },
  watch: {
    model() {
      if (this.value !== this.model) {
        this.value = this.model
      }
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 10px;
}
</style>
