<template>
  <div>
    <textarea name="code" class="is-hidden"></textarea>
    <div id="code" class="code-editor-box"></div>
    <div class="has-text-centered lang-info">Language for the question is
      <span class="has-text-weight-bold" v-if="!doodleForm.language || doodleForm.language.name==='none'">None, Please select your language of choice in Try Section</span>
      <span class="has-text-weight-bold" v-if="doodleForm.language && doodleForm.language.name!=='none'">{{doodleForm.language.displayName}}
            <span class="has-text-weight-bold" v-if="doodleForm.language && doodleForm.language.versions.length>0"> and the Version is {{doodleForm.language.versions[doodleForm.versionIndex]}}</span>
      </span>
    </div>
    <div class="has-text-centered">
      <a class="button is-small" v-on:click="openTrySection=true" v-show="!openTrySection">Open Try Section</a>
      <a class="button is-small" v-on:click="openTrySection=false" v-show="openTrySection">Close Try Section</a>
    </div>
    <div v-show="openTrySection" class="margin-top-10px well">
      <div class="columns" v-if="!doodleForm.language || this.doodleForm.language.name==='none'">
        <div class="column is-6">
          <div class="ide-title">Language </div>
          <DoodleSelect :model="tryLanguage"
                        v-model="tryLanguage" :rule="'lang_required'" field-name="Language"
                        :list="languages" display-item="false" display-property="displayName" @change="onTryLanguageChange"/>
        </div>
        <div class="column is-6" v-if="showIndexBox">
          <div class="ide-title">Version </div>
          <DoodleSelect v-model="versionIndex" :model="versionIndex"
                        field-name="Version" :list="tryLanguage.versions" display-item="true"/>
        </div>
      </div>
      <div class="ide-title">CommandLine Arguments </div>
      <DoodleInput field-name="args" type="text" v-model="args" :model="args" hide-tick-mark="true"/>
      <div v-show="!interactiveMode">
        <div class="ide-title">Stdin Inputs </div>
        <DoodleTextArea field-name="stdin" :model="stdin"  v-model="stdin" rows="3"/>
      </div>
      <div class="field has-text-centered margin-top-10px">
        <input id="interactiveMode"  v-model="interactiveModeOption"  type="checkbox" name="interactiveMode" class="switch has-text-white"/>
        <label for="interactiveMode" class="ide-title has-text-weight-semibold">Interactive</label>
      </div>
      <div class="has-text-centered margin-top-10px">
        <span>
          <button class="button is-info has-text-white has-text-weight-bold" @click="ideExecute">
            <span v-show="!isCodeExecuting"><font-awesome-icon icon="play"/>&nbsp;&nbsp;&nbsp;Execute</span>
            <span v-if="isCodeExecuting">
                    <font-awesome-icon icon="spinner" class="fa-spin"/>&nbsp;&nbsp;&nbsp;Executing...</span>
          </button>
        </span>
        <span v-if="isCodeExecuting">&nbsp;&nbsp;&nbsp;
          <button class="button has-text-weight-bold" @click="stopExecution">
            <span><font-awesome-icon icon="stop-circle"/></span>
          </button>
        </span>
      </div>
      <div>
        <div class="ide-title">Result </div>
        <div class="is-success execute-time" v-show="!isCodeExecuting && executionTime">
          <span>CPU Time: {{this.cpuTime}} sec(s), Memory: {{this.memory}} kilobyte(s)</span>
        </div>
        <div id="output" class="code-editor-box"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ideMixin from '../../../assets/javascript/ide-mixin'
import loadScriptInBody from '../../../assets/javascript/loadScriptInBody'
import DoodleInput from '../../Utils/DoodleInput'
import DoodleTextArea from '../../Utils/DoodleTextArea'
import DoodleSelect from '../../Utils/DoodleSelect'
import { ACE_UTIL } from '@/assets/javascript/ace-util'
import { IDE_CONST } from '@/assets/javascript/constants'
export default {
  name: 'guruQuestionIDE',
  components: { DoodleSelect, DoodleTextArea, DoodleInput },
  props: ['doodleForm', 'languages', 'changeSignal', 'disabled'],
  mixins: [ideMixin],
  created () {
    this.$validator.extend('lang_required', {
      getMessage: field => 'Please select a language',
      validate: (value, [testProp]) => {
        return value.name !== 'none'
      }
    })
  },
  mounted () {
    this.language = this.doodleForm.language.name
    this.tryLanguage = this.doodleForm.language
    this.versionIndex = this.doodleForm.versionIndex
    ACE_UTIL.fixAceeIOSBug()
    loadScriptInBody.loadScriptInBody('/assets/javascript/ace.min.js')
      .then(() => {
        loadScriptInBody.loadScriptInBody('/assets/javascript/ext-language_tools.js')
        loadScriptInBody.loadScriptInBody('/assets/javascript/ext-static_highlight.js')
        this.$_.delay(this.initEditors, 500, 1)
      })

    let setOnChange = (count) => {
      if (this.codeEditor) {
        if (this.doodleForm.answer) {
          this.codeEditor.getSession().setValue(this.doodleForm.answer)
        }

        if (this.disabled) {
          this.codeEditor.setReadOnly(true)
        } else {
          this.codeEditor.getSession().on('change', () => { this.$emit('setCode', this.codeEditor.getSession().getValue()) })
        }
      } else if (count < 40) {
        this.$_.delay(setOnChange, 100, count++)
      }
    }
    this.$nextTick(() => { setOnChange(0) })
  },
  data: function () {
    return {
      interactiveModeOption: false,
      openTrySection: false,
      tryLanguage: {}
    }
  },
  methods: {
    onTryLanguageChange () {
      this.language = this.tryLanguage.name
      if (this.tryLanguage.versions.length > 0) {
        this.versionIndex = 0
      }
    },
    ideExecute () {
      if (this.isCodeExecuting) {
        return
      }

      this.isCodeExecuting = true
      this.executionTime = null
      this.outputFiles = []
      window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue('JDoodle in Action.... Running the program...')

      this.execute(false)
    }
  },
  computed: {
    aceLanguageCode () {
      return this.tryLanguage.aceCode ? this.tryLanguage.aceCode : this.tryLanguage.name
    },
    showIndexBox () {
      return this.tryLanguage && this.tryLanguage.versions &&
        this.tryLanguage.versions.length > 0 && this.versionIndex != null
    },
    interactiveMode () {
      return this.interactiveModeOption === true
    }
  },
  watch: {
    changeSignal () {
      if (this.doodleForm.answer) {
        this.codeEditor.getSession().setValue(this.doodleForm.answer)
      } else {
        this.codeEditor.getSession().setValue('')
      }
      this.language = this.doodleForm.language.name
      this.tryLanguage = this.doodleForm.language
      this.versionIndex = this.doodleForm.versionIndex
      this.stdin = ''
      this.args = ''
      this.executionTime = ''
      this.outputEditor.getSession().setValue('')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/ide";
@import "../../../../node_modules/bulma-switch/dist/css/bulma-switch.min.css";

  #code {
    font-size: 12px;
    font-weight: 500;
  }

  @media only screen and (max-width: 769px) {
    .code-editor-box {
      padding: 0;
    }
  }

  .code-editor-box {
    border: 1px solid #ddd;
    border-radius: 15px;
  }

  .lang-info {
    font-size: 0.8em;
  }

  .margin-top-10px {
    margin-top: 10px;
  }

  .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  }

  .ide-title {
    font-size: 0.9em;
    font-weight: bold;
  }
</style>
