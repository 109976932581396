import { GURU_CONSTANTS } from './constants'
import langListMixin from './language-list-mixin'

export default {
  mixins: [langListMixin],
  data: function () {
    return {
      doodleForm: this.questionFormData(),
      ideCodeChangeSignal: null
    }
  },
  methods: {
    questionFormData () {
      return {
        assQuestionId: '',
        question: '',
        questionType: '',
        language: {},
        versionIndex: null,
        markForCorrectAnswer: null,
        optionLevelMarking: false,
        negativeMark: null,
        correctAnswerOption: null,
        options: [],
        testCases: [],
        args: '',
        stdin: '',
        output: '',
        openTrySection: false,
        isCodeExecuting: false,
        executionTime: null,
        memory: 0,
        cpuTime: 0,
        isMarkedForReview: false,
        answer: null,
        evaluated: false,
        autoCorrectNotes: '',
        minLength: null,
        maxLength: null,
        mark: null,
        comment: null
      }
    },
    loadQuestionMixin (successCallback, options) {
      this.doodleForm.errorMessage = ''
      this.executeAPIWitoutValiation({
        url: '/api/guru-api/assignment/getQuestion',
        data: {
          instituteCode: this.currentAssociation.institute.code,
          assignmentId: this.assignment.insAssignmentId,
          questionId: this.doodleForm.assQuestionId,
          forStudent: this.isAttend,
          markAsStarted: this.isAttend,
          saveAnswer: this.isSaveAnswer(),
          answer: this.stringifiedAnswer(),
          isMarkedForReview: this.doodleForm.isMarkedForReview,
          saveQuestionId: this.doodleForm.questionIdToSave,
          reachedReview: this.doodleForm.reachedReview,
          loadAnswer: this.isEvaluate,
          saveEvaluation: this.isSaveEvaluation() && options.save,
          studentEmail: (this.studentUnderEvaluation) ? this.studentUnderEvaluation : null,
          mark: this.doodleForm.mark,
          autoCorrectNotes: this.doodleForm.autoCorrectNotes,
          evaluated: this.doodleForm.evaluated,
          currentQuestionId: this.doodleForm.questionIdToSave,
          isPublicLibrary: false,
          forLibraryPreview: false
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          Object.assign(this.doodleForm, this.questionFormData())
          Object.assign(this.doodleForm, data.question)
          this.doodleForm.questionType = this.questionTypes.find((o) => {
            return this.doodleForm.questionType === o.value
          })

          if (this.isAttend && this.doodleForm.answer) {
            this.doodleForm.isMarkedForReview = this.doodleForm.answer.isMarkedForReview
            if (this.doodleForm.answer.responseText) {
              if (this.isProgramQuestion || this.isOpenEndedQuestion) {
                this.doodleForm.answer = this.doodleForm.answer.responseText
              } else {
                try {
                  this.doodleForm.answer = JSON.parse(this.doodleForm.answer.responseText)
                } catch (e) {
                  console.log(e)
                }
              }
            } else {
              this.doodleForm.answer = null
            }
          } else if (this.isEvaluate && this.doodleForm.answer) {
            if (!this.isProgramQuestion && !this.isOpenEndedQuestion) {
              try {
                this.doodleForm.answer = JSON.parse(this.doodleForm.answer)
              } catch (e) {
                console.log(e)
              }
            }
          }

          if (this.isAttend && this.doodleForm.comment) {
            this.doodleForm.autoCorrectNotes = this.doodleForm.comment
          }

          if (!this.doodleForm.answer && this.isMultipleChoiceQuestion) {
            this.doodleForm.answer = []
          } else
          if (this.isProgramQuestion) {
            if (this.doodleForm.language) {
              this.doodleForm.language = this.languages.find((o) => { return o.name === this.doodleForm.language })
            } else {
              this.doodleForm.language = this.language[0]
            }
            this.doodleForm.ideCodeChangeSignal = this.doodleForm.assQuestionId
          }
          successCallback.call()
        },
        jdaEvent: 'guru-get-question',
        jdaLabel: ''
      })
    },
    isSaveAnswer () {
      return this.isAttend && this.assignment.status === GURU_CONSTANTS.ASSIGNMENT_STATUS.IN_PROGRESS &&
        (this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS ||
          this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.YET_TO_START) && window._.isNumber(this.doodleForm.questionIdToSave)
    },
    isSaveEvaluation () {
      return this.isEvaluate && this.assignment.status !== GURU_CONSTANTS.ASSIGNMENT_STATUS.DRAFT && window._.isNumber(this.doodleForm.questionIdToSave) &&
        this.assignment.submissionStatus !== GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.RESULT_ANNOUNCED
    },
    stringifiedAnswer () {
      if (this.isMultipleChoiceQuestion) {
        if (this.doodleForm.answer.length === 0) {
          return null
        }
        return JSON.stringify(this.doodleForm.answer)
      }

      return this.doodleForm.answer
    }
  },
  computed: {
    isSingleChoiceQuestion () {
      return this.doodleForm.questionType === GURU_CONSTANTS.QUESTION_TYPES[1]
    },
    isMultipleChoiceQuestion () {
      return this.doodleForm.questionType === GURU_CONSTANTS.QUESTION_TYPES[2]
    },
    isChoiceQuestion () {
      return this.isSingleChoiceQuestion || this.isMultipleChoiceQuestion
    },
    isOpenEndedQuestion () {
      return this.doodleForm.questionType === GURU_CONSTANTS.QUESTION_TYPES[3]
    },
    isProgramQuestion () {
      return this.doodleForm.questionType === GURU_CONSTANTS.QUESTION_TYPES[4]
    },
    isYesOrNoQuestion () {
      return this.doodleForm.questionType === GURU_CONSTANTS.QUESTION_TYPES[5]
    },
    questionTypes () {
      return GURU_CONSTANTS.QUESTION_TYPES
    }
  }
}
