<template>
  <div class="field">
    <label class="label has-text-left">{{ labelText }}</label>
    <div class="select is-fullwidth is-small has-text-weight-bold version-dropdown"
      :class="{ 'is-danger': errors.has(fieldName) }" v-if="displayItem === 'true'">
      <select v-model="value" @change="$emit('change')" v-on:input="emitInputIndex($event)" v-validate="rule"
        :name="fieldName">
        <option v-for="(item, index) in list" v-bind:value="index" v-bind:key="index">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="select is-fullwidth is-small has-text-weight-bold version-dropdown"
      :class="{ 'is-danger': errors.has(fieldName) }" v-else-if="displayItem === 'false'">
      <select v-model="value" @change="$emit('change')" v-on:input="emitInput($event)" v-validate="rule"
        :name="fieldName">
        <option v-for="(item, index) in list" v-bind:value="item" v-bind:key="index">
          {{ item[displayProperty] }}
        </option>
      </select>
    </div>
    <span v-show="errors.has(fieldName)" class="help is-danger">{{ errors.first(fieldName) }}</span>
  </div>
</template>

<script>
export default {
  name: 'DoodleSelect',
  props: ['labelText', 'model', 'list', 'displayItem', 'displayProperty', 'rule', 'fieldName'],
  data: function () {
    return {
      value: () => {
        if (this.displayItem === 'true') {
          return '' + this.model
        } else {
          return this.model
        }
      },
    }
  },
  methods: {
    emitInput($event) {
      this.$emit('input', this.list[$event.target.selectedIndex])
    },
    emitInputIndex($event) {
      this.$emit('input', $event.target.selectedIndex)
    }
  },
  watch: {
    model() {
      if (this.value !== this.model) {
        this.value = this.model
      }
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 10px;
}
</style>
