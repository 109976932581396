import { render, staticRenderFns } from "./GuruQuestionIDE.vue?vue&type=template&id=ede67270&scoped=true&"
import script from "./GuruQuestionIDE.vue?vue&type=script&lang=js&"
export * from "./GuruQuestionIDE.vue?vue&type=script&lang=js&"
import style0 from "./GuruQuestionIDE.vue?vue&type=style&index=0&id=ede67270&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ede67270",
  null
  
)

export default component.exports