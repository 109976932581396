<template>
  <DoodleGeneralForm in-progress-message="Loading, Please wait..."
                     form="" no-box="true"
                     :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                     :errorMessageText="doodleForm.errorMessage"
                     :hide-action-close="true">
  <div class="columns is-marginless is-paddingless">
    <div class="column is-10-tablet is-offset-1-tablet is-paddingless">
      <div class="question">
        {{this.localQuestionIndex+1}}. <span class="white-space-pre" v-html="this.doodleForm.question"></span>
      </div>
      <div v-if="isYesOrNoQuestion">
        <div class="option-block"><label><input type="radio" v-model="doodleForm.answer" v-bind:value="true" :disabled="disableAnswering"/>&nbsp;&nbsp;&nbsp;True</label></div>
        <div class="option-block"><label><input type="radio" v-model="doodleForm.answer"  v-bind:value="false" :disabled="disableAnswering"/>&nbsp;&nbsp;&nbsp;False</label></div>
      </div>
      <div v-else-if="isChoiceQuestion">
        <div class="option-block" v-for="(option, index) in doodleForm.options" v-bind:key="index">
          <label>
            <input type="radio" v-model="doodleForm.answer" v-bind:value="option.qusTestCaseId" :disabled="disableAnswering"  v-if="isSingleChoiceQuestion"/>
            <input type="checkbox" v-model="doodleForm.answer" v-bind:value="option.qusTestCaseId" :disabled="disableAnswering" v-if="isMultipleChoiceQuestion"/>
            &nbsp;&nbsp;&nbsp;{{option.question}}
          </label>
        </div>
      </div>
      <div v-else-if="isOpenEndedQuestion">
        <DoodleTextArea  :model="doodleForm.answer" v-model="doodleForm.answer" field-name="answer" :rows="6" :disabled="disableAnswering" :rule="openEndedMinMaxRule"/>
        <div class="guru-assignment-taking-open-min-max" v-if="openEndedHasMinOrMaxLimit">(
          <span v-if="doodleForm.minLength">Min Length: {{doodleForm.minLength}} Chars</span>
          <span v-if="doodleForm.minLength && doodleForm.maxLength">,</span>
          <span v-if="doodleForm.maxLength">Max Length: {{doodleForm.maxLength}} Chars</span>)</div>
      </div>
      <div v-else-if="isProgramQuestion">
        <GuruQuestionIDE :doodleForm="doodleForm" :languages="languages" v-model="doodleForm.answer"  :disabled="disableAnswering"
                         :changeSignal="doodleForm.ideCodeChangeSignal" :model="doodleForm.answer" @setCode="setCode"/>
      </div>
      <div class="button-panel"  v-if="!disableAnswering">
        <label><input type="checkbox" v-model="doodleForm.isMarkedForReview"/> Mark for Review</label>
      </div>
      <div v-if="isAttend && isResultAnnounced" class="button-panel">
        <div v-if="hasMark" class="has-text-weight-bold">Mark: {{doodleForm.mark}}</div>
        <div v-if="doodleForm.autoCorrectNotes">
          <DoodleTextArea field-name="comment" label-text="Comment:" :model="doodleForm.autoCorrectNotes" v-model="doodleForm.autoCorrectNotes" disabled="true" :rows="maxRowsForComment"/>
        </div>
      </div>
      <div v-if="isEvaluate" class="button-panel">
          <DoodleInput field-name="mark" label-text="Mark" type="text" v-model="doodleForm.mark" rule="required|regex:^[-]?[0-9]+$"
                       :model="doodleForm.mark" :disabled="isResultAnnounced || isEvaluated || isReevaluationRequested" :hide-tick-mark="isResultAnnounced" v-show="resultTypeMarkAndComment || resultTypeMarkOnly"/>
          <DoodleTextArea field-name="comment" label-text="Comment" :model="doodleForm.autoCorrectNotes" v-model="doodleForm.autoCorrectNotes"
                          :disabled="disableCommentForEvaluation"  v-show="showCommentsForEvaluate" :rows="maxRowsForComment"/>
          <label>
            <input type="checkbox" v-model="doodleForm.evaluated" :model="doodleForm.evaluated" :disabled="isResultAnnounced || isEvaluated || isReevaluationRequested"/>
            &nbsp;&nbsp;&nbsp;Mark as Evaluated
          </label>
      </div>
      <div class="button-panel">
        <span v-if="disableAnswering"><button class="button is-primary has-text-weight-bold" @click="gotoSummary">Goto Summary</button>&nbsp;&nbsp;</span>
        <span v-if="isEvaluate && showSaveForEvaluation"><button class="button has-text-weight-bold is-info" @click="saveEvaluation">Save</button>&nbsp;&nbsp;&nbsp;</span>
        <span v-if="isEvaluate && hasPrevQuestion && showSaveForEvaluation"><button class="button has-text-weight-bold is-info" @click="prevQuestion(true)">Save and Previous</button>&nbsp;&nbsp;&nbsp;</span>
        <span v-if="isEvaluate && hasNextQuestion && showSaveForEvaluation"><button class="button has-text-weight-bold is-info" @click="nextQuestion(true)">Save and Next</button>&nbsp;&nbsp;&nbsp;</span>
        <span v-show="showPrevQuestion"><button class="button has-text-weight-bold" @click="prevQuestion(false)">Previous</button>&nbsp;&nbsp;&nbsp;</span>
        <span v-show="showNextQuestion"><button class="button has-text-weight-bold" @click="nextQuestion(false)">Next</button>&nbsp;&nbsp;</span>
        <span v-if="reachedSubmissionPage && !isEvaluate && !disableAnswering"><button class="button has-text-weight-bold is-primary" @click="gotoSubmissionPage">Goto Submit Page</button>&nbsp;&nbsp;&nbsp;</span>
      </div>
      <div v-if="isAttend" class="has-text-weight-bold has-text-centered button-panel">
        <button class="button exit-preview" @click="saveAndExit"><span v-if="isStudentDoingIt">Save And&nbsp;</span>Exit</button>
      </div>
    </div>
  </div>
  </DoodleGeneralForm>
</template>

<script>
import formMixin from '../../../assets/javascript/form-mixin'
import questionMixin from '../../../assets/javascript/guru-question-mixin'
import DoodleTextArea from '../../Utils/DoodleTextArea'
import GuruQuestionIDE from './GuruQuestionIDE'
import DoodleGeneralForm from '../../Utils/DoodleGeneralForm'
import { GURU_CONSTANTS } from '../../../assets/javascript/constants'
import DoodleInput from '../../Utils/DoodleInput'

export default {
  name: 'guruAssignmentSummary',
  components: { DoodleInput, DoodleGeneralForm, GuruQuestionIDE, DoodleTextArea },
  mixins: [formMixin, questionMixin],
  props: ['currentAssociation', 'isAccountOwner', 'isInstituteAdmin', 'isTeacher', 'isStudent', 'assignment',
    'questionIndex', 'isPreview', 'submissions', 'reachedSubmissionPage', 'isAttend', 'isEvaluate', 'studentUnderEvaluation'],
  data: function () {
    return {
      localQuestionIndex: null
    }
  },
  mounted () {
    this.localQuestionIndex = this.questionIndex
    this.loadQuestion()
  },
  methods: {
    gotoSummary () {
      this.$emit('loadQuestionSummaryPage')
    },
    updatePreviewSubmissions () {
      if (this.isPreview) {
        this.$emit('updatePreviewSubmission', this.localQuestionIndex, {
          questionId: this.localQuestionIndex,
          answer: this.doodleForm.answer,
          isMarkedForReview: this.doodleForm.isMarkedForReview
        })
      }
    },
    setCode (code) {
      this.doodleForm.answer = code
    },
    prevQuestion (save) {
      this.updatePreviewSubmissions()
      if (this.localQuestionIndex === 0) {
        this.saveAnswerAndGotoSummaryPage()
      } else {
        this.doodleForm.questionIdToSave = this.doodleForm.assQuestionId
        this.localQuestionIndex--
        this.loadQuestion(save)
      }
    },
    nextQuestion (save) {
      this.updatePreviewSubmissions()
      if (this.localQuestionIndex === this.assignment.questions.length - 1) {
        this.saveAnswerAndGotoSubmissionPage()
      } else {
        this.doodleForm.questionIdToSave = this.doodleForm.assQuestionId
        this.localQuestionIndex++
        this.loadQuestion(save)
      }
    },
    gotoSubmissionPage () {
      this.updatePreviewSubmissions()
      this.saveAnswerAndGotoSubmissionPage()
    },
    saveAnswerAndGotoSubmissionPage () {
      if (this.isPreview) {
        this.$emit('loadQuestionSubmissionPage', {})
        return
      }

      if (!this.isAttend) {
        return
      }

      this.doodleForm.errorMessage = ''
      this.executeAPIWitoutValiation({
        url: '/api/guru-api/assignment/saveAnswerAndPreviewResponses',
        data: {
          instituteCode: this.currentAssociation.institute.code,
          assignmentId: this.assignment.insAssignmentId,
          saveAnswer: true,
          answer: this.stringifiedAnswer(),
          isMarkedForReview: this.doodleForm.isMarkedForReview,
          saveQuestionId: this.doodleForm.assQuestionId,
          reachedReview: true
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          this.$emit('loadQuestionSubmissionPage', data)
        },
        jdaEvent: 'guru-save-answer-goto-submission',
        jdaLabel: ''
      })
    },
    saveAnswerAndGotoSummaryPage () {
      if (this.isPreview) {
        this.$emit('loadQuestionSummaryPage')
        return
      }
      this.saveAnswer((data) => {
        this.$emit('loadQuestionSummaryPage')
      }, 'guru-save-answer-goto-summary', this.reachedSubmissionPage)
    },
    saveAndExit () {
      if (!this.isAttend) {
        return
      }

      if (this.isStudentDoingIt) {
        this.saveAnswer((data) => {
          this.$router.push('/guru/ins/' + this.$route.params.instituteCode)
        }, 'guru-save-answer-goto-preview', this.reachedSubmissionPage)
      } else {
        this.$router.push('/guru/ins/' + this.$route.params.instituteCode)
      }
    },
    saveAnswer (successAction, event, reachedReview) {
      this.doodleForm.errorMessage = ''
      this.executeAPIWitoutValiation({
        url: '/api/guru-api/assignment/saveAnswer',
        data: {
          instituteCode: this.currentAssociation.institute.code,
          assignmentId: this.assignment.insAssignmentId,
          answer: this.stringifiedAnswer(),
          isMarkedForReview: this.doodleForm.isMarkedForReview,
          saveQuestionId: this.doodleForm.assQuestionId,
          reachedReview: reachedReview
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          if (successAction) {
            successAction.call(data)
          }
        },
        jdaEvent: event,
        jdaLabel: ''
      })
    },
    saveEvaluation () {
      this.doodleForm.errorMessage = ''
      this.executeAPIWitoutValiation({
        url: '/api//guru-api/assignment/saveEvaluation',
        data: {
          instituteCode: this.currentAssociation.institute.code,
          assignmentId: this.assignment.insAssignmentId,
          studentEmail: this.studentUnderEvaluation,
          mark: this.doodleForm.mark,
          autoCorrectNotes: this.doodleForm.autoCorrectNotes,
          evaluated: this.doodleForm.evaluated,
          currentQuestionId: this.doodleForm.assQuestionId
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        jdaEvent: 'guru-save-evaluation',
        jdaLabel: ''
      })
    },
    loadQuestion (save) {
      this.doodleForm.assQuestionId = this.assignment.questions[this.localQuestionIndex].id
      if (this.isPreview) {
        this.loadQuestionMixin(() => {
          let submission = this.submissions[this.localQuestionIndex]
          this.doodleForm.answer = submission.answer
          if (this.isProgramQuestion) {
            this.ideCodeChangeSignal = this.doodleForm.assQuestionId
          } else if (this.isMultipleChoiceQuestion) {
            if (!this.doodleForm.answer) {
              this.doodleForm.answer = []
            }
          }
          this.doodleForm.isMarkedForReview = submission.isMarkedForReview
        }, {})
      } else if (this.isAttend || this.isEvaluate) {
        this.loadQuestionMixin(() => {
          if (this.isProgramQuestion) {
            this.ideCodeChangeSignal = this.doodleForm.assQuestionId
          }
        }, { save: save })
      } else {
        this.$router.push('/guru')
      }
    }
  },
  computed: {
    disableAnswering () {
      return !this.isPreview && this.assignment.submissionStatus !== GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS && this.assignment.submissionStatus !== GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.YET_TO_START
    },
    isStudentDoingIt () {
      return this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS ||
        this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.YET_TO_START
    },
    isResultAnnounced () {
      return this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.RESULT_ANNOUNCED
    },
    isReevaluationRequested () {
      return this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.REEVALUATION_REQUESTED
    },
    isEvaluated () {
      return this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.EVALUATED
    },
    hasPrevQuestion () {
      return this.localQuestionIndex > 0
    },
    hasNextQuestion () {
      return this.localQuestionIndex < this.assignment.questions.length - 1
    },
    showNextQuestion () {
      return this.isPreview || (this.isEvaluate && this.hasNextQuestion) ||
        (this.isAttend && (this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS ||
          this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.YET_TO_START)) ||
        (this.isAttend && this.assignment.submissionStatus !== GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS && this.hasNextQuestion)
    },
    showPrevQuestion () {
      return this.isPreview || (this.isEvaluate && this.hasPrevQuestion) ||
        (this.isAttend && (this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS ||
          this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.YET_TO_START)) ||
        (this.isAttend && this.assignment.submissionStatus !== GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS && this.hasPrevQuestion)
    },
    openEndedMinMaxRule () {
      if (this.isOpenEndedQuestion) {
        if (this.doodleForm.minLength && this.doodleForm.maxLength) {
          return 'min:' + this.doodleForm.minLength + '|' + 'max:' + this.doodleForm.maxLength
        } else if (this.doodleForm.minLength) {
          return 'min:' + this.doodleForm.minLength
        } else if (this.doodleForm.maxLength) {
          return 'max:' + this.doodleForm.maxLength
        }
      }

      return ''
    },
    openEndedHasMinOrMaxLimit () {
      return this.isOpenEndedQuestion && (this.doodleForm.minLength || this.doodleForm.maxLength)
    },
    resultTypeMarkAndComment () {
      return this.assignment.resultType === 0
    },
    resultTypeMarkOnly () {
      return this.assignment.resultType === 1
    },
    resultTypeCommentOnly () {
      return this.assignment.resultType === 2
    },
    showCommentsForEvaluate () {
      return this.resultTypeMarkAndComment || this.resultTypeCommentOnly || this.assignment.autoCorrect
    },
    disableCommentForEvaluation () {
      return this.isResultAnnounced || this.isEvaluated || this.isReevaluationRequested || (this.resultTypeMarkOnly && this.assignment.autoCorrect)
    },
    showSaveForEvaluation () {
      return this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.SUBMITTED ||
        this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.ERROR_WHEN_AUTO_CORRECT ||
        this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.PARTIALLY_EVALUATED
    },
    maxRowsForComment () {
      if (this.isPreview || !this.doodleForm.autoCorrectNotes) {
        return 0
      }

      let noOfLines = (this.doodleForm.autoCorrectNotes.match(/\n/g) || []).length

      if (noOfLines > 18) {
        return 20
      }

      return noOfLines + 2
    },
    hasMark () {
      return window._.isNumber(this.doodleForm.mark)
    }
  }
}
</script>

<style scoped lang="scss">
  .question {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .button-panel {
    margin-top: 25px;
    button {
      margin-top:10px
    }
  }

  .option-block {
    padding: 3px;
  }

  .guru-assignment-taking-open-min-max {
    font-weight: bold;
    font-size: 0.8em;
    margin-top: -10px;
  }
</style>
